<template>
  <sub-page
      :title="$t('CoursesStatistics')"
      v-model="$store.state.statistics.pageData"
      :filters="filters"
  >

    <div class="d-flex justify-end mt-3 pr-4">
      <v-btn @click="exportData" class="noCaps" :color="wsACCENT" outlined>
        {{ $t('Download')}}
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>

    <ws-data-table
        :items="itemsFiltered"
        :headers="headers"
        :search="$store.state.statistics.filtersData.search"
        class="mt-5"
    >

      <template #item="{item}">
       <tr>
         <td>
           <h5>{{ item.name }}</h5>
           <h6 class="font-weight-medium">{{ item.email }}</h6>
         </td>
         <td>
           <h5>{{ item.phone }}</h5>
         </td>

         <template v-for="(field , i) in customFieldsFilters" >
           <td :key="i + item.uuid">
             <h5 class="text-center">{{ item['custom_field_' + field.id] }}</h5>
           </td>
         </template>


         <td v-for="(course , i) in coursesSelectFiltered " :key="course.value"
             :style="coursesColumnBorderStyle(i)"
         >
           <div :set="courseResult = item['course_' +  course.value] " v-if="item['course_' +  course.value]">

             <v-chip v-if="courseResult.passed" :color="wsSUCCESS" dark>
               <h5>{{ $t('CourseAverage')   }} : {{ courseResult.result }}</h5>
             </v-chip>

            <h5 v-else class="text-center" :style="`color : ${ wsACCENT }`" >{{ $t('InProcess') }} : {{ courseResult.completion_percent }}% </h5>

           </div>

           <div v-else>
            <h5 class="text-center" :style="`color : ${wsDARKLIGHT}`">{{ $t('HaveNotEnrolled') }}</h5>
           </div>

         </td>
       </tr>
      </template>



    </ws-data-table>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "statisticsCourses",
  data() {
    return {
      items : [],
      coursesSelect : [],
      customFieldsFilters : [],
    }
  },
  computed : {
    filters() {
      let items = [
        { text : this.$t('CourseActive')  , value : 'active' , type : 'select' , items : this.CHOICE_ACTIVE_SELECT },
        { text : this.$t('Courses')  , value : 'courses' , type : 'select_multiple' , items : this.coursesSelect },
        ...this.customFieldsFilters
      ]

      return items
    },
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      this.customFieldsFilters.forEach(field => {

        if ( field.type === 'select_multiple' && this.$store.state.statistics.filtersData[field.value] ) {

          if ( Array.isArray( this.$store.state.statistics.filtersData[ field.value])  ) {
            if (this.$store.state.statistics.filtersData[field.value].length > 0 ) {
              items = items.filter( el=> this.$store.state.statistics.filtersData[field.value].includes(el[field.value]) )
            }
          }
        }
      })

      return items
    },
    headers() {
      let items = [
        { text : this.$t('HumanName') , value : 'name' },
        { text : this.$t('Phone') , value : 'phone' }
      ]

      this.customFieldsFilters.forEach(field => {
        items.push( { text : field.text , value : field.value })
      })

      if ( this.coursesSelect.length > 0 ) {
        this.coursesSelectFiltered.forEach( el=> {
          items.push( { text : el.text , value : 'course_' + el.value })
        })
      }
      return items
    },
    coursesSelectFiltered() {
      let items = this.coursesSelect
      if ( items.length === 0)   {
        return []
      }

      if ( this.$store.state.statistics.filtersData.courses ) {
        if (Array.isArray(this.$store.state.statistics.filtersData.courses)) {
          items = items.filter( el=> this.$store.state.statistics.filtersData.courses.includes(el.value) )
        }
      }

      if ( this.$store.state.statistics.filtersData.active === true || this.$store.state.statistics.filtersData.active === false ) {
        items = items.filter( el=> el.active === this.$store.state.statistics.filtersData.active )
      }

      return items
    }
  },
  watch : {
    coursesSelect : {
      handler() {
        this.$store.state.statistics.pageData.filters = this.filters
        this.$store.state.statistics.pageData = Object.assign({} , this.$store.state.statistics.pageData )
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('statistics',[
        "GET_STUDY_STATISTICS"
    ]),
    ...mapActions('export',[
      'EXPORT_STATISTICS_STUDY'
    ]),
    async exportData() {
      let data = {
        headers : this.headers,
        items : this.itemsFiltered,
      }
      let blob = await this.EXPORT_STATISTICS_STUDY(data)
      if ( !blob ) {
        return
      }
      var url  = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'import_data.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      this.notify(this.$t('TableExported'))

    },
    async initPage() {
      let result = await this.GET_STUDY_STATISTICS()
      if ( !result ) {
        return
      }
      this.items = result.students
      this.coursesSelect = result.courses
      this.customFieldsFilters = result.custom_fields_filters
    },
    //technical
    coursesColumnBorderStyle(index)  {
      let style = '';

      if ( index === 0 ) {
        style += `border-left: 1px solid ${this.wsBACKGROUND} ;`
      }

      if ( index !== this.coursesSelectFiltered.length -1 ) {
        style += `border-right: 1px solid ${this.wsBACKGROUND} ;`
      }

      return style

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>